import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

// import Styles from "./profile.module.css"
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function ProfileComponent({ props }) {
  const navigate = useNavigate();
  const [users, setUsers] = useState({});
  const [mount, setMount] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      let { value } = await props.getUserDetails();
      if (get(value, "success", "") === true) {
        setUsers(get(value, "user", {}));
      }
      setMount(true);
    };
    if (!mount) {
      callApi();
    }
  }, [users]);

  const handleChange = (name, value) => {
    users[name] = value;
    setUsers({ ...users });
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const updateUser = async () => {
    if (!get(users, 'firstName', '')) {
    } else if (!get(users, 'email', '')) {
    } else if (!get(users, 'lastName', '')) {
    } else {
        let { value } = await props.updateUser(users);

        if (get(value, 'success', '') === true) {
            openNotificationWithIcon('success', 'Update details successfully');
            setTimeout(() => {
                setIsloading(false);
                navigate('/shows');
            }, 4000);
        }
    }
};

const validationForName = () => {
  if (
    users.firstName !== '' &&
    users.lastName !== '' &&
    users.email !== '' &&
    users.firstName.trim().length >= 1 &&
    users.lastName.trim().length >= 1 &&
    users.email.trim().length >= 3
  ) {
    const pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
    const email = users.email;
    if (email.match(pattern)) {
      setIsloading(true);
      updateUser();
    } else {
      openNotificationWithIcon('error', 'Wrong Email Address');
    }
  } else {
    openNotificationWithIcon('error', 'Please Fill Mandatory Fields');
  }
};

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              First Name<span className="required">*</span>
            </label>
            <input
              type="text"
              value={get(users, "firstName", "")}
              name="firstName"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="form-control"
              required
            />
            {!get(users, "firstName", "") && (
              <div className="invalid-feedback">Please enter first name</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label-primary">
              Last Name<span className="required">*</span>
            </label>
            <input
              type="text"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={get(users, "lastName", "")}
              name="lastName"
              className="form-control"
              required
            />
            {!get(users, "lastName", "") && (
              <div className="invalid-feedback">Please enter last name</div>
            )}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="label-primary">
          Email Address<span className="required">*</span>
        </label>
        <input
          type="text"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={get(users, "email", "")}
          name="email"
          className="form-control"
          required
        />
        {!get(users, "email", "") && (
          <div className="invalid-feedback">Please enter email</div>
        )}
      </div>

      <button
        type="submit"
        className="btn btn-lg btn__green w-100"
        onClick={() => validationForName()}
      >
        {isLoading ? <Spin indicator={antIcon} /> : "Save"}
      </button>
    </>
  );
}
