import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { User } from "../../icons";
import Logo from "../../../images/logo.png";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// import Styles from "./topNavigation.module.css"

export default function TopNavigationComponent({ props }) {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("Authorization");
    navigate("/");
  };
  const [users, setUsers] = useState({});
  const [mount, setMount] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      let { value } = await props.getUserDetails();
      if (get(value, "success", "") === true) {
        setUsers(get(value, "user", {}));
      }
      setMount(true);
    };
    if (!mount) {
      callApi();
    }
  }, [users]);

  const handleNavigate = () => {
    setShowLoader(true);
    console.log('done')
    setTimeout(() => {
      navigate("/shows");
      setShowLoader(false);
    }, 2000);
  };

  return (
    <>
      <header className="top__navigation top__navigation--fixed">
        <div className="top__navigation--container">
          <div className="top__navigation--row">
            <div className="top__navigation--left">
              {/*             
              <div className="top__navigation--logo">
                <Link to="/" className="top__navigation--brand">
                  <img src={Logo} alt="Gen-Z Media" />
                </Link>
              </div> */}

              <div className="top__navigation--logo">
                <div
                  onClick={handleNavigate}
                  style={{ cursor: "pointer" }}
                  className="top__navigation--brand"
                >
                  <img src={Logo} alt="Gen-Z Media" />
                </div>
              </div>

              {showLoader && (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              )}
            </div>
            <div className="top__navigation--right navigation-right">
            <button
                onClick={() => {
                  console.log("navigated");
                  navigate(`/premium-show-from-megaphone`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green me-3"
              >
                Add New Premium Show from Megaphone
              </button>
            <button
                onClick={() => {
                  console.log("navigated");
                  navigate(`/new-show-from-megaphone`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green me-3"
              >
                Add New Show from Megaphone
              </button>
              {/* <button
                onClick={() => {
                  console.log("navigated");
                  navigate(`/new-show-from-art-19`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green me-3"
              >
                Add New Show from Art19
              </button> */}
              <button
                onClick={() => {
                  navigate(`/send-push-notification`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green"
              >
                Send Push Notification
              </button>
              <div className="top__navigation--nav">
                <>
                  <Dropdown
                    trigger={["click"]}
                    placement="topRight"
                    overlayClassName="top__navigation--dropdown"
                    overlay={
                      <Menu>
                        <div className="profile__wrapper">
                          <div className="profile__name">
                            {users.firstName} {users.lastName}
                          </div>
                          <div className="profile__role">Admin</div>
                        </div>
                        <Menu.Item key="1">
                          <Link
                            to="/account/profile"
                            className="top__navigation--dropdown-link"
                          >
                            Profile
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={() => logout()}
                          className="top__navigation--dropdown-btn"
                        >
                          Sign out
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link nav__link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <User />
                    </a>
                  </Dropdown>
                </>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}