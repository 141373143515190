import React from "react"
import { useEffect, useState } from "react"
import Logo from "../../images/logo.png"
import { useNavigate } from "react-router-dom";
import { get } from 'lodash'
import Styles from "./login.module.css"
import { Spin } from "antd";
import InputMask from "react-text-mask"
import { LoadingOutlined } from "@ant-design/icons";
const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function LoginComponent(props) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const navigate = useNavigate();
  const login = () => {
    setIsLoading(true)
    props.loginUser({ phoneNumber: phoneNumber, role: 'Admin' })
  }

  useEffect(() => {
    let isAuthenticate = localStorage.getItem('Authorization')
    if (isAuthenticate) {
      navigate('/shows')
    }
  }, [])

  useEffect(() => {
    if (get(props, 'loginPhase', '') === 'success') {
      props.resetLoginPhase()
      setIsLoading(false)
      navigate('/verify-code', { state: { phoneNumber } })
    } else if (get(props, 'loginPhase', '') === 'error') {
      props.resetLoginPhase()
      setIsLoading(false)
      setMessage(get(props, 'message', ''))
    }
  }, [props])

  return (
    <>
      <main className={Styles.main}>
        <div className={Styles.wrapper}>
          <div className={Styles.head}>
            <div className={Styles.logo}>
              <img src={Logo} alt="Gen-Z Media" />
            </div>
            <h1 className={Styles.title}>Gen-Z Media App Dashboard</h1>
          </div>
          <div className="form-group">
            <label className="label-primary">Phone Number</label>
            <InputMask
              guide={false}
              type="text"
              id="phone"
              keepCharPositions={false}
              mask={phoneNumberMask}
              className="form-control  form-control-flat"
              name="phone"
              onChange={(e) => {
                setMessage('')
                setPhoneNumber(e.target.value)
              }}
              value={phoneNumber}
              required
            />
            {message &&
              <div className="invalid-feedback">{message}</div>
            }
          </div>
          <div>
            <button onClick={() => login()} type="button" className="btn btn-lg btn__purple w-100">
              {isLoading ? <Spin indicator={antIcon} /> : "Request Login Code"}
            </button>
          </div>
        </div>
      </main>
    </>
  )
}
