import React, { useEffect, useState } from "react"
import Logo from "../../images/logo.png"
import { useLocation, useNavigate } from "react-router-dom";
import { get } from 'lodash'
import Styles from "./verificationCode.module.css"
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function VerificationCodeComponent(props) {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ otp, setOtp ] = useState('')
  const location = useLocation();
  const navigate = useNavigate();
  

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  
  useEffect(() => {
    const { state } = location;
    setPhoneNumber(get(state,'phoneNumber',''));
    let isAuthenticate = localStorage.getItem('Authorization')
    if(isAuthenticate){
      navigate('/shows')
    }
  }, [])

  const verifyUser = () => {
    setMessage('')
    setIsLoading(true)
    const payload = {otp: otp, phoneNumber: phoneNumber}
    props.verifyUser(payload)
  }

  useEffect(() => {
    if(get(props,'verifyUserPhase','d') === 'success'){
      props.resetLoginPhase()
      setIsLoading(false)
      setMessage(get(props,'verifyMessage',''))
      navigate('/shows')
    }else if(get(props,'verifyUserPhase','d') === 'error'){
      props.resetLoginPhase()
      setIsLoading(false)
      setMessage(get(props,'verifyMessage',''))
    }
  }, [props, message, isLoading])

  return (
    <>
      <main className={Styles.main}>
        <div className={Styles.wrapper}>
          <div className={Styles.head}>
            <div className={Styles.logo}>
              <img src={Logo} alt="Gen-Z Media" />
            </div>
            <h1 className={Styles.title}>Verify it’s you </h1>
            <p className={Styles.subTitle}>We sent you a 6-digit code to mobile number so you can login </p>
            <div className={Styles.codeSent}>
              code sent to: {phoneNumber}
            </div>
          </div>
          <div className="form-group">
            <label className="label-primary">Verification code</label>
            <input onChange={(e) => {
              setMessage('')
              if(e.target.value.length==7) 
                return false;   
                        setOtp(e?.target.value);  
            }
            } type="number" name="otp"  value={otp} className="form-control" required />
            {message &&
              <div className="invalid-feedback">{message}</div>
            }
          </div>
          <div>
            <button onClick={() => {
              verifyUser() }
            } type="button" className="btn btn-lg btn__purple w-100">
              {isLoading ? <Spin indicator={antIcon} /> : "Verify Code"} 
            </button>
          </div>
        </div>
      </main>
    </>
  )
}
