import { connect } from 'react-redux'
import { verifyUser, resetLoginPhase } from '../../store/dashboard/duck'
import VerificationCodeComponent from './component'
const VerificationCodeContainer = connect(
  // Map state to props
  state => ({
    verifyUserPhase: state.dashboard.verifyUserPhase,
    verifyMessage: state.dashboard.verifyMessage,
    userData: state.dashboard.userData
  }),
  // Map actions to dispatch and props
  {
    verifyUser,
    resetLoginPhase
  }
)(VerificationCodeComponent)
export default VerificationCodeContainer
