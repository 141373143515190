import React, { useEffect, useState } from "react";
import TopNavigationComponent from "./../../components/common/TopNavigation/component";
import { useLocation, useNavigate } from "react-router-dom";
import Shows from "./Shows/component";
import AppHomeScreen from "./AppHomeScreen/component";
import Categories from "./Categories/component";
import "./styles.scss";

export default function LeadPagesComponent(props) {
  const [tab, setTab] = useState("list-shows");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname === "/shows") {
      setTab("list-shows");
      navigate("/shows", { state: {} });
    } else if (pathname === "/app-home-screen") {
      setTab("app-home-screen");
      navigate("/app-home-screen", { state: {} });
    } else {
      setTab("categories");
      navigate("/categories", { state: {} });
    }
  }, [tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // props.getAllCategories({ pageNumber: 1, pageSize: 20 });
    // props.getShowDetailsById({ id: params.id });
  }, []);

  const onTabChange = (tab) => {
    setTab(tab);
    if (tab === "list-shows") {
      navigate("/shows");
    } else if (tab === "categories") {
      navigate("/categories");
    } else {
      navigate("/app-home-screen");
    }
  };

  return (
    <>
      <div className="main__header">
        <TopNavigationComponent onTabChange={onTabChange} props={props} />
      </div>
      <div className="tabs__links tabs__links--space">
        <button
          onClick={() => onTabChange("list-shows")}
          className={tab === "list-shows" ? "tabs__link active" : "tabs__link"}
        >
          List of Shows
        </button>
        <button
          onClick={() => onTabChange("app-home-screen")}
          className={
            tab === "app-home-screen" ? "tabs__link active" : "tabs__link"
          }
        >
          App Home Screen
        </button>
        <button
          onClick={() => onTabChange("categories")}
          className={tab === "categories" ? "tabs__link active" : "tabs__link"}
        >
          Categories
        </button>
      </div>
      {tab === "list-shows" ? (
        <Shows {...props} />
      ) : tab === "app-home-screen" ? (
        <AppHomeScreen {...props} />
      ) : (
        <Categories {...props} />
      )}
    </>
  );
}
