import React, { useEffect, useState } from "react";
import { Edit, Plus, Close, DragIcon } from "../../../components/icons";
import { get, orderBy, set, size } from "lodash";
import ReactModal from "react-modal";
import { Spin, notification, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import ActionMoreComponent from "../../../components/common/ActionMore/component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const { Option } = Select;

export default function CategoriesComponent(props) {
  const [list, setList] = useState([]);
  const [nextStep, setNextStep] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [modalIsShowOpen, setIsShowOpen] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedData, setSelectedData] = useState({});
  const [openModalData, setOpenModalData] = useState({});
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [showsListData, setShowsListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [initialState, setInitialState] = useState({ showList: [] });
  const [sameCategori, setSameCategori] = useState(false);
  const [emptyName, setEmptyName] = useState(true);
  const [textChange, setTextChange] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const openModalDelete = (data) => {
    setSelectedData(data);
    setDeleteOpenModal(true);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setEmptyName(false);
    setIsOpen(false);
    setSelectedData({});
    setIsShowOpen(false);
    setDeleteOpenModal(false);
  }

  const onSelectedStatus = (key, value) => {
    setSelectedStatus(value);
  };

  const handleChangeCategories = (index, data) => {
    const lists = initialState["showList"];
    var ind = data.children ? data.children : index;

    if (!lists.includes(data.value)) {
      lists.push(data.value);
      initialState["showList"] = lists;
      setInitialState({ ...initialState });
    }
  };

  const handleRemoveCategories = (e, data) => {
    const lists = initialState["showList"];
    const index = lists.indexOf(e);

    if (index > -1) {
      lists.splice(index, 1);
      initialState["showList"] = lists;
      setInitialState({ ...initialState });
    }
  };

  // const handleChange = async (id) => {
  //   let test = episodes;
  //   const data = test.find((t) => t._id.toString() === id.toString());
  //   let newStatus = !data?.episodeObjectImported?.attributes?.published;
  //   let newEpisode = [];
  //   let s = test.map((t) => {
  //     if (t) {
  //       if (t?._id.toString() === id.toString()) {
  //         t.episodeObjectImported.attributes.published = newStatus;
  //       }
  //       newEpisode.push(t);
  //     }
  //   });
  //   await Promise.all(s);
  //   setEpisodes(newEpisode);
  //   const { value } = await props.episodeShowHide({ id: id });
  // };

  useEffect(() => {
    setisLoading(true);
    props.getAllCategories({ pageNumber: 1, pageSize: 20 });
  }, []);

  useEffect(() => {
    if (get(props, "categoryPhase", "") === "success") {
      setList(props.categoryData);
      props.resetLoginPhase();
      let nextPageCount = parseInt(Math.ceil(props.categoryCount / 20));
      setNextStep(nextPageCount);
      setisLoading(false);
      props.resetLoginPhase();
    } else if (get(props, "categoryPhase", "") === "error") {
      props.resetLoginPhase();
    }
  }, [props, list]);

  useEffect(() => {
    const callApi = async () => {
      const { value } = await props.showList();
      setLoader(false);
      setShowsListData(get(value, "data", []));
    };
    callApi();
  }, []);

  const handleChangeText = (name, value) => {
    if (name === "name") {
      setCategoryName(value);
      setTextChange(true);
    } else {
      setTextChange(true);
      selectedData[name] = value;
      setSelectedData({ ...selectedData });
    }
  };

  function openModal() {
    setIsOpen(true);
  }
  const pageChange = (value) => {
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      props.getAllCategories({ pageNumber: pageNum, pageSize: 20 });
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      props.getAllCategories({ pageNumber: pageNum, pageSize: 20 });
    }
  };

  const activeStatus = [
    { key: "active", value: "Active" },
    { key: "inactive", value: "Not Active" },
  ];

  const deleteCategories = async (id) => {
    let { value } = await props.deleteCategories({ _id: id });
    if (get(value, "success", "")) {
      props.getAllCategories({ pageNumber: 1, pageSize: 20 });
      openNotificationWithIcon(
        "success",
        "Deleted featured details successfully.",
      );
      setEmptyName(false);
      setIsOpen(false);
      setDeleteOpenModal(false);
      setShowPreviewArr({});
      setSelectedData({});
    }
  };

  // const addCategory = async () => {
  // 	if (!categoryName) {
  // 	} else {
  // 		const newName = categoryName.trim().toLowerCase();
  // 		const dataMatch = list.filter(
  // 			(catName) => catName.name.trim().toLowerCase() === newName
  // 		);
  // 		if (textChange === false) {
  // 			setTextChange(false);
  // 			setEmptyName(false);
  // 			setSameCategori(false);
  // 			setisLoading(true);

  // 			selectedData['status'] = selectedStatus;
  // 			let { value } = await props.addCategory(selectedData);
  // 			if (get(value, 'success', '') === true) {
  // 				let { values } = await props.assignCategories({
  // 					id: value?.data?._id,
  // 					showList: initialState?.showList,
  // 				});
  // 				let time = setInterval(() => {
  // 					openNotificationWithIcon(
  // 						'success',
  // 						'Add Categories details successfully.'
  // 					);
  // 					props.getAllCategories({ pageNumber: 1, pageSize: 30 });
  // 					setIsOpen(false);
  // 					setisLoading(false);
  // 					setCategoryName("");
  // 					setSelectedData({});
  // 					setInitialState({ showList: [] });
  // 					clearInterval(time);
  // 				}, 1000);
  // 			}
  // 		} else {
  // 			if (dataMatch.length > 0) {
  // 				setEmptyName(false);
  // 				setSameCategori(true);
  // 			} else {
  // 				if (newName === '') {
  // 					setEmptyName(true);
  // 				} else {
  // 					setTextChange(false);
  // 					setEmptyName(false);
  // 					setSameCategori(false);
  // 					setisLoading(true);
  // 					selectedData['status'] = selectedStatus;
  // 					let { value } = await props.addCategory(selectedData);
  // 					if (get(value, 'success', '') === true) {
  // 						let { values } = await props.assignCategories({
  // 							id: value?.data?._id,
  // 							showList: initialState?.showList,
  // 						});
  // 						let time = setInterval(() => {
  // 							openNotificationWithIcon(
  // 								'success',
  // 								'Add Categories details successfully.'
  // 							);
  // 							props.getAllCategories({ pageNumber: 1, pageSize: 30 });
  // 							setIsOpen(false);
  // 							setisLoading(false);
  // 							setSelectedData({});
  // 							setCategoryName("");
  // 							setInitialState({ showList: [] });
  // 							clearInterval(time);
  // 						}, 1000);
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // };

  const addCategory = async () => {
    if (!categoryName) {
    } else {
      const newName = categoryName.trim().toLowerCase();
      const dataMatch = list.filter(
        (catName) => catName.name.trim().toLowerCase() === newName,
      );
      if (textChange === false) {
        setTextChange(false);
        setEmptyName(false);
        setSameCategori(false);
        setisLoading(true);
        selectedData["name"] = categoryName;
        selectedData["status"] = selectedStatus;
        let { value } = await props.addCategory(selectedData);
        if (get(value, "success", "") === true) {
          let { values } = await props.assignCategories({
            id: value?.data?._id,
            showList: initialState?.showList,
          });
          let time = setInterval(() => {
            openNotificationWithIcon(
              "success",
              "Add Categories details successfully.",
            );
            props.getAllCategories({ pageNumber: 1, pageSize: 30 });
            setIsOpen(false);
            setisLoading(false);
            setCategoryName("");
            setSelectedData({});
            setInitialState({ showList: [] });
            clearInterval(time);
          }, 1000);
        }
      } else {
        if (dataMatch.length > 0) {
          setEmptyName(false);
          setSameCategori(true);
        } else {
          if (newName === "") {
            setEmptyName(true);
          } else {
            setTextChange(false);
            setEmptyName(false);
            setSameCategori(false);
            setisLoading(true);
            selectedData["status"] = selectedStatus;
            selectedData["name"] = categoryName;
            let { value } = await props.addCategory(selectedData);
            if (get(value, "success", "") === true) {
              let { values } = await props.assignCategories({
                id: value?.data?._id,
                showList: initialState?.showList,
              });
              let time = setInterval(() => {
                openNotificationWithIcon(
                  "success",
                  "Add Categories details successfully.",
                );
                props.getAllCategories({ pageNumber: 1, pageSize: 30 });
                setIsOpen(false);
                setisLoading(false);
                setSelectedData({});
                setCategoryName("");
                setInitialState({ showList: [] });
                clearInterval(time);
              }, 1000);
            }
          }
        }
      }
    }
  };

  const onDragEnd = (result) => {
    const newItems = Array.from(list);
    const [removed] = newItems.splice(result?.source?.index, 1);
    newItems.splice(result?.destination?.index, 0, removed);
    setList(newItems);
    props.updateCategoriesOrderIndex({ categoriesData: newItems });
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row">
            <div className="page__header--left">
              <h1 className="page__header--title">Categories</h1>
            </div>
            <div className="page__header--right">
              <button
                onClick={() => {
                  setEmptyName(false);
                  setSelectedData({});
                  setCategoryName("");
                  setInitialState({ showList: [] });
                  openModal();
                }}
                type="button"
                className="btn btn__green"
              >
                <Plus className="btn__icon" /> Add Categories
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          {list.length === 0 && isLoading ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
              }
              className="loader__full"
            />
          ) : (
            <div className="table table__custom drag-table-container">
              <div className="draggable-continer-head">
                <div className="title-block">Category Title</div>
                <div className="status">Status</div>
                <div className="action-description">
                  Number of shows/episodes
                </div>
              </div>

              {/* <tbody>
                {list.length > 0 &&
                  list.map((l, i) => (
                    <tr key={i}>
                      <td>{l.name}</td>
                      <td>
                        {l.status === "active" ? (
                          <span className="table__status active">Active</span>
                        ) : (
                          <span
                            className="table__status not-active"
                            style={{ color: "#FF69B4" }}
                          >
                            Not Active
                          </span>
                        )}
                      </td>
                      
                      {l.seriesCount?.length > 0 ?(
                      <td>
                        {l.seriesCount?.length} 
                          <span> (
                        {l.seriesCount?.map((s, i) => { 
                          if (i < l.seriesCount.length - 1) {
                            return <span>{s.title}, </span>;
                          } else {
                            return <span>{s.title}</span>;
                          }
                        })})</span>
                      </td>
                      ):(
                        <td>
                        0
                        </td>

                      )}
                      <td className="table__cell--last">
                        <button
                          onClick={() => {
                            setSelectedData(l);
                            setSelectedStatus(l?.status);
                            openModal();
                          }}
                          className="btn"
                        >
                          <Edit />
                        </button>
                        <ActionMoreComponent
                            
                            setModalIsOpen={setIsOpen}
                            setOpenModalData={setSelectedData}
                            openModalHide={openModalDelete}
                             data={l}
                            keyU="Category"
                          />
                      </td>
                    </tr>
                  ))}
              </tbody> */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      className="timeline-block scroll-block"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {list &&
                        list.map((l, i) => {
                          return (
                            <Draggable
                              key={l._id}
                              draggableId={l._id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="draggable-row"
                                  key={l._id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  provided={provided}
                                  snapshot={snapshot}
                                >
                                  <div className="title-block">
                                    <div
                                      className="short-desc pointer-table-item"
                                      onClick={() => {
                                        setEmptyName(false);
                                        setCategoryName(l?.name);
                                        setSelectedData(l);
                                        let newArr = [];
                                        let result = l?.seriesCount?.map(
                                          (s, i) => {
                                            if (
                                              s.appDisplayStatus == "active"
                                            ) {
                                              newArr.push(s?._id);
                                            }
                                          },
                                        );
                                        Promise.all(result);
                                        initialState["showList"] = newArr;
                                        setInitialState({ ...initialState });
                                        setSelectedStatus(l?.status);
                                        openModal();
                                      }}
                                    >
                                      <DragIcon className="drag-icon" />
                                      {l.name}
                                    </div>
                                  </div>
                                  <div className="status-column">
                                    {l.status === "active" ? (
                                      <span className="table__status active">
                                        Active
                                      </span>
                                    ) : (
                                      <span
                                        className="table__status not-active"
                                        style={{ color: "#FF69B4" }}
                                      >
                                        Not Active
                                      </span>
                                    )}
                                  </div>

                                  <div className="description-column">
                                    {l.seriesCount?.length > 0 ? (
                                      <div>
                                        {l.seriesCount?.length &&
                                        l.seasonCount?.length
                                          ? l.seriesCount?.length +
                                            l.seasonCount?.length
                                          : l.seriesCount?.length}
                                        <span>
                                          {" "}
                                          (
                                          {l.seriesCount?.map((s, i) => {
                                            if (i < l.seriesCount.length - 1) {
                                              return <span>{s.title}, </span>;
                                            } else {
                                              return <span>{s.title}</span>;
                                            }
                                          })}
                                        </span>
                                        {l.seasonCount?.length > 0 && <>,</>}
                                        <span>
                                          {" "}
                                          {l.seasonCount?.map((u, index) => {
                                            if (
                                              index <
                                              l.seasonCount.length - 1
                                            ) {
                                              return (
                                                <span>
                                                  {u.seriesTitle} | {u.title},{" "}
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span>
                                                  {u.seriesTitle} | {u.title}{" "}
                                                </span>
                                              );
                                            }
                                          })}
                                          )
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        {l.seasonCount?.length > 0 ? (
                                          <span>
                                            {l.seasonCount?.length} (
                                            {l.seasonCount?.map((u, index) => {
                                              if (
                                                index <
                                                l.seasonCount.length - 1
                                              ) {
                                                return (
                                                  <span>
                                                    {u.seriesTitle} | {u.title},{" "}
                                                  </span>
                                                );
                                              } else {
                                                return (
                                                  <span>
                                                    {u.seriesTitle} | {u.title}{" "}
                                                  </span>
                                                );
                                              }
                                            })}
                                            )
                                          </span>
                                        ) : (
                                          <div>0</div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="table__cell--last">
                                    <button
                                      onClick={() => {
                                        setEmptyName(false);
                                        setCategoryName(l?.name);
                                        setSelectedData(l);
                                        let newArr = [];
                                        let result = l?.seriesCount?.map(
                                          (s, i) => {
                                            newArr.push(s?._id);
                                          },
                                        );
                                        Promise.all(result);
                                        initialState["showList"] = newArr;
                                        setInitialState({ ...initialState });
                                        setSelectedStatus(l?.status);
                                        openModal();
                                      }}
                                      className="btn me-2"
                                    >
                                      <Edit />
                                    </button>

                                    <ActionMoreComponent
                                      setModalIsOpen={setIsOpen}
                                      setOpenModalData={setSelectedData}
                                      status={setSelectedStatus}
                                      openModalHide={openModalDelete}
                                      data={l}
                                      setInitialState={setInitialState}
                                      initialState={initialState}
                                      catName={setCategoryName}
                                      keyU="Category"
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {list.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(list)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={deleteOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Delete</h5>
            <p className="react-modal-subtitle">
              Are you sure you want to delete this? This cannot be undone.
            </p>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => {
                setSameCategori(false);
                setTextChange(false);
                closeModal();
              }}
            >
              <Close />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="text-right">
              <button
                onClick={() => deleteCategories(selectedData._id)}
                className="btn btn-danger btn-lg w-150"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Category Detail</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Category Title</label>
              <input
                name="name"
                value={categoryName}
                onChange={(e) => {
                  setSameCategori(false);
                  handleChangeText(e.target.name, e.target.value);
                }}
                className="form-control custom-select material-textfield-input"
                required
              />
              {emptyName === true || categoryName === "" ? (
                <div className="invalid-feedback">
                  Please enter category name
                </div>
              ) : (
                sameCategori === true && (
                  <div className="invalid-feedback">
                    This category name is already taken
                  </div>
                )
              )}
            </div>
            <div className="custom__radio__group">
              {activeStatus.map((d, i) => {
                return (
                  <>
                    <input
                      className="custom__radio__group--button"
                      id={d.key}
                      type="radio"
                      checked={selectedStatus === d.key}
                      onClick={() => {
                        setSelectedData["status"] = d.key;
                        onSelectedStatus("appDisplayStatus", d.key);
                      }}
                    />
                    <label
                      className="custom__radio__group--label  category-label"
                      htmlFor={d.key}
                    >
                      {d.value}
                    </label>
                  </>
                );
              })}
            </div>
            {/* <div className="form-group mt-3">
            <label className="label-primary">Shows</label>
                <div className="checkbox-listing">
                 
                  {selectedData?.seriesCount?.map((s, i) => {
                    return(
                      <div className="category-checkbox">
                         <div>{(s?.title)}</div>
                        <div className="hide-ep-block me-3">
                        <input
                            type="checkbox"
                            id="subscribe"
                            name="subscribe"
                            defaultChecked={true}
                            />
                            </div>
                      
                      </div>
                    );
                  })}
                  </div>
            </div> */}
            <div className="form-group">
              <label className="label-primary">Select Show(s) to add </label>
              <Select
                value={get(initialState, "showList", [])}
                mode="tags"
                style={{ width: "100%" }}
                className="multiple__tags__textfield"
                onSelect={handleChangeCategories}
                onDeselect={handleRemoveCategories}
                filterOption={(input, option) =>
                  option.props.value
                    ?.toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {showsListData.length > 0 &&
                  showsListData.map((d, key) => {
                    return (
                      <>
                        {
                          <Option key={key} value={get(d, "_id", "")}>
                            {get(d, "title", "")}
                          </Option>
                        }
                      </>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => {
                setSelectedData({});
                setSameCategori(false);
                setTextChange(false);
                closeModal();
              }}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              onClick={() => addCategory()}
              type="submit"
              className="btn btn__purple"
            >
              {isLoading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
        </div>
      </ReactModal>
      {/*  </main>
       */}
    </>
  );
}
