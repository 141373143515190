import * as React from "react"

const DragIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      fill="none"
      viewBox="0 0 14 20"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.947 1.974a1.974 1.974 0 11-3.947 0 1.974 1.974 0 013.947 0zM13.991 1.974a1.974 1.974 0 11-3.947 0 1.974 1.974 0 013.947 0zM3.947 10A1.974 1.974 0 110 10a1.974 1.974 0 013.947 0zM13.991 10a1.974 1.974 0 11-3.947 0 1.974 1.974 0 013.947 0zM3.947 18.026a1.974 1.974 0 11-3.947 0 1.974 1.974 0 013.947 0zM13.991 18.026a1.974 1.974 0 11-3.947 0 1.974 1.974 0 013.947 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
export default DragIcon
