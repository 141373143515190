import { connect } from 'react-redux'
import { loginUser, resetLoginPhase } from '../../store/dashboard/duck'
import LoginComponent from './component'

const LoginContainer = connect(
  // Map state to props
  state => ({
    loginPhase: state.dashboard.loginPhase,
    message: state.dashboard.message
  }),
  // Map actions to dispatch and props
  {
    loginUser,
    resetLoginPhase
  }
)(LoginComponent)

export default LoginContainer
