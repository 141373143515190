
import { connect } from 'react-redux'
import { getUserDetails, updateUser } from '../../store/dashboard/duck'
import AccountComponent from './component'

const AccountContainer = connect(
  // Map state to props
  state => ({

  }),
  // Map actions to dispatch and props
  {
    getUserDetails,
    updateUser
  }
)(AccountComponent)

export default AccountContainer
