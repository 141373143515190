import { connect } from "react-redux";
import {
  getAllShows,
  uploadImages,
  deleteFeaturedShows,
  getUserDetails,
  addCategory,
  getAllCategories,
  featuredShows,
  showList,
  getFeaturedShows,
  resetLoginPhase,
  syncShowFromArt,
  addFeaturedText,
  getFeaturedText,
  deleteCategories,
  getAllData,
  updateOrderIndex,
  updateCategoriesOrderIndex,
  assignCategories,
  hideFeatureEpisodes,
} from "../../store/dashboard/duck";
import LeadPagesComponent from "./component";

const LeadPagesShowsContainer = connect(
  // Map state to props
  (state) => ({
    categoryCount: state.dashboard.categoryCount,
    categoryData: state.dashboard.categoryData,
    categoryPhase: state.dashboard.categoryPhase,
    showCount: state.dashboard.showCount,
    showData: state.dashboard.showData,
    showPhase: state.dashboard.showPhase,
    featuredShowPhase: state.dashboard.featuredShowPhase,
    featuredShowData: state.dashboard.featuredShowData,
    featuredShowCount: state.dashboard.featuredShowCount,
    addFeaturedTextData: state.dashboard.addFeaturedTextData,
    addFeaturedTextPhase: state.dashboard.addFeaturedTextPhase,
    getFeaturedTextData: state.dashboard.getFeaturedTextData,
    getFeaturedTextPhase: state.dashboard.getFeaturedTextPhase,
    updateCategoriesOrderIndexData:
      state.dashboard.updateCategoriesOrderIndexData,
    updateCategoriesOrderIndexPhase:
      state.dashboard.updateCategoriesOrderIndexPhase,
    updateOrderIndexData: state.dashboard.updateOrderIndexData,
    updateOrderIndexPhase: state.dashboard.updateOrderIndexPhase,
    assignCategoriesData: state.dashboard.assignCategoriesData,
    assignCategoriesPhase: state.dashboard.assignCategoriesPhase,

  }),
  // Map actions to dispatch and props
  {
    getUserDetails,
    getAllData,
    uploadImages,
    deleteFeaturedShows,
    addCategory,
    getAllCategories,
    featuredShows,
    showList,
    getFeaturedShows,
    syncShowFromArt,
    resetLoginPhase,
    getAllShows,
    addFeaturedText,
    getFeaturedText,
    deleteCategories,
    updateCategoriesOrderIndex,
    updateOrderIndex,
    assignCategories,
    hideFeatureEpisodes,
  }
)(LeadPagesComponent);

export default LeadPagesShowsContainer;
