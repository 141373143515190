import React, { useState, useEffect, useReducer } from "react";
import { Close } from "../../../components/icons";
// import { Select } from "antd";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function SendPushNotificationsComponent(props) {
  const navigate = useNavigate();
  const initial_page_state = {
    is_notification_title_error: false,
    is_notification_description_error: false
  }
  const [page_state, set_page_state] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initial_page_state)
  const [initialState, setInitialState] = useState({
    title: "",
    description: "",
  });

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "Created",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const activeStatus = [
    { key: "show", value: "Show" },
    { key: "hide", value: "Hide" },
  ];

  const handleTextChange = (name, value) => {
    initialState[name] = value;
    setInitialState({ ...initialState });
  };


  const sendNotification = async () => {
    try {
      let err
      if (initialState?.title?.length <= 0) {
        err = true
        set_page_state({ is_notification_title_error: true })
      }
      if (initialState?.description?.length <= 0) {
        err = true
        set_page_state({ is_notification_description_error: true })
      }
      if (!err) {
        const { value } = await props.sendGeneralNotifications(initialState);
        openNotificationWithIcon("success", "Notification sent successfully.");
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.message);
    }
  };

  return (
    <>
      <div className="custom__container">
        <div className="main__header">
          <header class="head">
            <div class="head__container">
              <div class="head__row">
                <div class="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/shows")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div class="head__divider"></div>
                  <div class="head__title">Send Push Notification</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__wrap">
          <div className="form-group"></div>
          <div className="form-group">
            <label className="label-primary">
              Notification Title <span className="required">*</span>
            </label>
            <input
              type="text"
              name="title"
              onChange={(e) => {
                set_page_state({ is_notification_title_error: false })
                handleTextChange(e.target.name, e.target.value)
              }}
              value={get(initialState, "title", "")}
              className="form-control material-textfield-input"
            />
            {
              page_state?.is_notification_title_error &&
              <div className="invalid-feedback">
                Please enter notification title.
              </div>
            }
          </div>
          <div className="form-group">
            <label className="label-primary">
              Notification Description <span className="required">*</span>
            </label>
            <div className="form-group material-textfield">
              <textarea
                type="text"
                className="form-control material-textfield-input"
                name="description"
                required
                onChange={(e) => {
                  set_page_state({ is_notification_description_error: false })
                  handleTextChange(e.target.name, e.target.value)
                }}
                value={get(initialState, "description", "")}
                cols={20}
                rows={10}
                wrap="description"
              ></textarea>
              {
                page_state?.is_notification_description_error &&
                <div className="invalid-feedback">
                  Please enter notification description.
                </div>
              }
            </div>
          </div>
          <button
            onClick={() => sendNotification()}
            type="submit"
            className="btn btn-lg btn__green w-100"
          >
            Send
          </button>
        </div>
      </div>
    </>
  );
}