import React from "react"
import TopNavigation from "../../components/common/TopNavigation/component"
import { Export } from "../../components/icons"

// import Styles from "./admin.module.css"

export default function AdminComponent() {
  return (
    <>
      <main className="main" role="header">
        <div className="main__header">
          <TopNavigation />
        </div>
        <div className="page__header">
          <div className="page__header--container">
            <div className="page__header--row">
              <div className="page__header--left">
                <h1 className="page__header--title">Admin</h1>
              </div>
              <div className="page__header--right">
                <button type="button" className="btn btn__default">
                  <Export className="btn__icon" /> Export
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs__links tabs__links--space">
          <button className="tabs__link active">Active</button>
          <button className="tabs__link">Not Active</button>
        </div>
        <div className="custom__container">
          <div className="table-responsive table__responsive--custom">
            <table className="table table__custom">
              <thead>
                <tr>
                  <th className="table__selection--column">
                    <label className="custom__checkbox--only">
                      <input
                        type="checkbox"
                        className="custom__checkbox--only-input"
                      />
                      <span className="custom__checkbox--only-check"></span>
                    </label>
                  </th>
                  <th>Sign up Date</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table__selection--column">
                    <label className="custom__checkbox--only">
                      <input
                        type="checkbox"
                        className="custom__checkbox--only-input"
                      />
                      <span className="custom__checkbox--only-check"></span>
                    </label>
                  </td>
                  <td>February 15, 2022</td>
                  <td>Johan </td>
                  <td>johan@email.com</td>
                  <td className="table__cell--last">
                    <button type="button" className="btn btn__link">
                      Impersonate
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table__blank">
            <div className="table__blank--text">No users</div>
          </div>

          <div className="table__pagination">
            <div className="table__pagination--results">10 results</div>
            <div className="table__pagination--nav">
              <button
                type="button"
                className="btn btn__default table__pagination--prev disabled"
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn__default table__pagination--next disabled"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
