import { connect } from "react-redux";
import NewPremiumShowFromMegaphone from "./AddPremiumShow/component";
import {
  getPremiumMegaphoneList,
  syncMegaphonePremiumSeriesRecords,

} from "../../store/dashboard/duck";

const UploadSeasonOrEpisodeContainer = connect(
  // Map state to props
  (state) => ({
    updateEpisodeDetailsPhase: state.dashboard.updateEpisodeDetailsPhase,
    getShowDetailsByIdPhase: state.dashboard.getShowDetailsByIdPhase,
    getShowDetailsByIdData: state.dashboard.getShowDetailsByIdData,
  }),
  // Map actions to dispatch and props
  {
    getPremiumMegaphoneList,
    syncMegaphonePremiumSeriesRecords
  }
)(NewPremiumShowFromMegaphone);

export default UploadSeasonOrEpisodeContainer;