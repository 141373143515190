import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

// components
import Admin from "../views/Admin/component";
import Login from "../views/Login/container";
import VerificationCode from "../views/VerificationCode/container";
import Account from "../views/Account/container";
import Onborading from "../views/Onborading/container";
import DeepLink from "../views/DeepLink/component";
import ShowDetails from "../views/ShowDetails/container.js";
import NewShow from "../views/NewShow/container";
import { Navigate } from "react-router-dom";
import Notification from "../views/Notification/container";
import NewShowFromArt19Component from "../views/NewShowFromArt19/container";
import NewShowFromMegaphone from "../views/NewShowFromMegaphone/container";
import NewPremiumShowFromMegaphone from "../views/NewPremiumShowFromMegaphone/container";
// import MegaphoneShows from "../views/MegaphoneShows/container";
// import MegaphoneDetail from "../views/MegaphoneDetail/container.js";

const PrivateRoute = ({ children }) => {
  return localStorage.getItem("Authorization") ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

function App(props) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login {...props} />} />
        <Route exact path="/login" element={<Login {...props} />} />
        <Route
          exact
          path="/verify-code"
          element={<VerificationCode {...props} />}
        />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/account/profile" element={<Account {...props} />} />
        <Route
          exact
          path="/shows"
          element={
            <PrivateRoute>
              <Onborading {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/app-home-screen"
          element={
            <PrivateRoute>
              <Onborading {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/categories"
          element={
            <PrivateRoute>
              <Onborading {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/show-details/:id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route exact path="/deeplink/:id" element={<DeepLink {...props} />} />
        <Route
          exact
          path="/deeplink/shows/:id"
          element={<DeepLink {...props} />}
        />
        <Route
          exact
          path="/episode-season/:id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/create-shows"
          element={
            <PrivateRoute>
              <NewShow {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/upload-season-or-episodes/:id"
          element={
            <PrivateRoute>
              <ShowDetails {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/send-push-notification"
          element={
            <PrivateRoute>
              <Notification {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/new-show-from-art-19"
          element={
            <PrivateRoute>
              <NewShowFromArt19Component {...props} />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/new-show-from-megaphone"
          element={
            <PrivateRoute>
              <NewShowFromMegaphone {...props} />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/premium-show-from-megaphone"
          element={
            <PrivateRoute>
              <NewPremiumShowFromMegaphone {...props} />
            </PrivateRoute>
          }
        />


       {/* <Route
          exact
          path="/megaphone-shows"
          element={
            <PrivateRoute>
              <MegaphoneShows {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/megaphone-app-home-screen"
          element={
            <PrivateRoute>
              <MegaphoneShows {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/megaphone-categories"
          element={
            <PrivateRoute>
              <MegaphoneShows {...props} />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/megaphone-show-details/:id"
          element={
            <PrivateRoute>
              <MegaphoneDetail {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/megaphone-episode-season/:id"
          element={
            <PrivateRoute>
              <MegaphoneDetail {...props} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/megaphone-upload-season-or-episodes/:id"
          element={
            <PrivateRoute>
              <MegaphoneDetail {...props} />
            </PrivateRoute>
          }
        /> */}

      </Routes>
    </Router>
  );
}

export default App;
