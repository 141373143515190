import React, { useState, useEffect } from "react";
import { Close } from "../../../components/icons";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />;

export default function NewPremiumShowFromMegaphone(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const openNotification = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const fetchPremiumMegaphoneList = async (pageNumber, pageSize) => {
    setLoading(true);
    try {
      const response = await props.getPremiumMegaphoneList({
        pageNumber,
        pageSize,
      });
      const data = response.value.result || [];
      const totalItems = response.value.pagination.totalItems || 0;
      setList(data);
      setTotalPages(Math.ceil(totalItems / pageSize));
    } catch (error) {
      console.error("Error fetching data:", error);
      setList([]);
      openNotification("error", "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPremiumMegaphoneList(currentPage, resultsPerPage);
  }, [currentPage]);

  const syncMegaphonePremiumSeriesRecords = async (id) => {
    setLoading(true);
    try {
      const value = await props.syncMegaphonePremiumSeriesRecords({ id });
      if (value?.value?.success === "true") {
        await fetchPremiumMegaphoneList(currentPage, resultsPerPage);
        openNotification(
          "success",
          "Sync premium series successfully. Episodes media play time is updating. You can activate these shows after 5 minutes."
        );
      } else {
        openNotification("error", "Syncing failed. Not found appropriate data in Megaphone.");
      }
    } catch (error) {
      console.error("Error syncing data:", error);
      openNotification("error", "Syncing failed due to an error.");
    } finally {
      setLoading(false);
    }
  };

  const onSave = () => {
    openNotification("success", "Saved changes successfully.");
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setLoading(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <div className="custom__container">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/shows")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">Megaphone Premium Shows</div>
                </div>
                <div className="head__right">
                  <button
                    type="button"
                    className="btn btn__green"
                    onClick={() => {
                      navigate("/shows");
                      onSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="container-small small-table-block">
          <div className="form-group"></div>
          <div className="custom__container">
            <div className="table-responsive table__responsive--custom">
              {loading ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                <>
                  <table className="table table__custom drag-table-container">
                    <thead>
                      <tr>
                        <th className="title-block">ALL MEGAPHONE PREMIUM SHOWS</th>
                        <th className="status">STATUS</th>
                        <th className="action-description text-center">
                          SYNC TO DATABASE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 ? (
                        list.map((item, index) => (
                          <tr key={index}>
                            <td style={{ whiteSpace: "normal" }}>{item.title}</td>
                            <td
                              className={`table__status ${item?.status === "Not Synced" ? "not-active" : "active"
                                }`}
                              style={{
                                color: item?.status === "Not Synced" ? "#FF69B4" : "#5ABDCE",
                              }}
                            >
                              {item.status}
                            </td>
                            <td className="text-center">
                              {item?.status === "Not Synced" && (
                                <button
                                  style={{ color: "#5ABDCE" }}
                                  className="sync-btn"
                                  onClick={() => syncMegaphonePremiumSeriesRecords(item?.seriesObjectImported.id)}
                                >
                                  <SyncOutlined />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="table__blank--text">No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="table__pagination">
                    <div className="table__pagination--results">
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                    </div>
                    <div className="table__pagination--nav">
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        Previous Page
                      </button>
                      <button
                        className="btn btn__default table__pagination--next"
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next Page
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
