import { connect } from "react-redux";
import NewShowFromArt19Component from "./AddShow/component";
import {
  getList,
  getMegaphoneList,
  syncSeriesRecords,
  syncMegaphoneSeriesRecords,

} from "../../store/dashboard/duck";

const UploadSeasonOrEpisodeContainer = connect(
  // Map state to props
  (state) => ({
    updateEpisodeDetailsPhase: state.dashboard.updateEpisodeDetailsPhase,
    getShowDetailsByIdPhase: state.dashboard.getShowDetailsByIdPhase,
    getShowDetailsByIdData: state.dashboard.getShowDetailsByIdData,
  }),
  // Map actions to dispatch and props
  {
    getList,
    syncSeriesRecords,
    getMegaphoneList,
    syncMegaphoneSeriesRecords
  }
)(NewShowFromArt19Component);

export default UploadSeasonOrEpisodeContainer;