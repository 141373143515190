import * as React from "react"

const Eye = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14c-4.418 0-8-4.686-8-6s3.582-6 8-6 8 4.686 8 6-3.582 6-8 6Zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill="#F04533"
      />
    </svg>
  );
};



export default Eye
