import React, { useState, useEffect, useReducer } from "react";
import { Remove, Plus, Close } from "../../../components/icons";
import { get, isEmpty } from "lodash";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function CreateShowsComponent(props) {
  const navigate = useNavigate();
  const initial_page_state = {
    is_show_name_error: false
  }
  const [page_state, set_page_state] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initial_page_state)

  const [coverPreviewArr, setCoverPreviewArr] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [coverLoader, setCoverLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [selectSeasons, setSelectedSeasons] = useState("");
  const [initialState, setInitialState] = useState({ appDisplayStatus: "inactive", cover_image: "", showCredits: "", showImage: "", featuredSeasonId: null });

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  useEffect(() => { }, [
    coverPreviewArr,
    imageError,
    showLoader,
    showPreviewArr,
    seasons,
  ]);

  const activeStatus = [
    { key: "show", value: "Show" },
    { key: "hide", value: "Hide" },
  ];

  const bytesToSize = (bytes) => bytes / (2048 * 2048);

  const addImages = async (acceptedFiles, keys) => {
    if (acceptedFiles.length > 0) {
      if (keys === "cover_image") {
        setCoverLoader(true);
      } else {
        setShowLoader(true);
      }
      setImageError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            acceptedFiles[key].preview = URL.createObjectURL(data);
            const fileSize = (bytesToSize(acceptedFiles[key].size) * 10) / 100;
            const newFle = await imageCompression(acceptedFiles[key], {
              maxSizeMB: fileSize,
            });
            const file = new File([newFle], "image");
            if (keys == "cover_image") {
              let form_data = new FormData();
              form_data.append("cover_image", newFle);
              let { value } = await props.uploadImages(form_data);
              initialState["cover_image"] = value?.url;
              setInitialState(initialState);
              setCoverPreviewArr(data);
              setCoverLoader(false);
            } else {
              let form_data = new FormData();
              form_data.append("showImage", newFle);
              let { value } = await props.uploadImages(form_data);
              initialState["showImage"] = value?.url;
              setInitialState(initialState);
              setShowPreviewArr(data);
              setShowLoader(false);
              // initialState['showImage'] = data
              // setInitialState(initialState)
            }
          } else {
            setImageError("Please use jpeg or png format.");
            setCoverLoader(false);
            setShowLoader(false);
          }
        });
      }
    } else {
      setImageError("Please use jpeg or png format.");
      setCoverLoader(false);
      setShowLoader(false);
    }
  };

  const handleTextChange = (name, value) => {
    console.log(value, "value----------->")
    console.log(name, "name----------->")
    initialState[name] = value;
    setInitialState({ ...initialState });
  };

  const updateShowDetails = async () => {
    try {
      let err
      if (initialState?.title?.length <= 0) {
        err = true
        set_page_state({ is_show_name_error: true })
      }
      if (!err) {
        if (!err) {
          if (!initialState.title || initialState.title.trim().length === 0) {
            openNotificationWithIcon("error", "Please enter a show name.");
          } else {
            console.log({ initialState })
            const { value } = await props.createShow(initialState);
            if (value?.success) {
              openNotificationWithIcon("success", "Created show successfully.");
            }
            else {
              openNotificationWithIcon("error", value?.message)
            }
          }
        }
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.message)
    }
  };

  return (
    <>
      <div className="custom__container">
        <div className="main__header">
          <header class="head">
            <div class="head__container">
              <div class="head__row">
                <div class="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/shows")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div class="head__divider">
                  </div>
                  <div class="head__title">Create Show</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__wrap">
          <div className="form-group"></div>
          <div className="form-group">
            <label className="label-primary">
              Show Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="title"
              onChange={(e) => {
                set_page_state({ is_show_name_error: false })
                handleTextChange(e.target.name, e.target.value)
              }}
              value={get(initialState, "title", "")}
              className="form-control material-textfield-input"
            />
            {
              page_state?.is_show_name_error &&
              <div className="invalid-feedback">Please enter title.</div>
            }
          </div>
          <div className="form-group">
            <label className="label-primary">
              Show Description
            </label>
            <div className="form-group material-textfield">
              <textarea
                type="text"
                className="form-control material-textfield-input"
                name="descriptionPlain"
                required
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                value={get(initialState, "descriptionPlain", "")}
                cols={20}
                rows={10}
                wrap="description"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label className="label-primary">Show Credits</label>
            <div className="form-group material-textfield">
              <textarea
                type="text"
                className="form-control material-textfield-input"
                name="showCredits"
                required
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                value={get(initialState, "showCredits", "")}
                cols={10}
                rows={5}
                wrap="showCredits"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label className="label-primary">
              Cover Photo (recommended dimensions){" "}
            </label>
            <Dropzone
              accept="image/*"
              multiple={false}
              // disabled={this.state.previewArr.length >= 50}
              onDrop={(acceptedFiles) =>
                addImages(acceptedFiles, "cover_image")
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div className="dropzone__area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button type="button" className="btn btn__green">
                      {coverLoader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>
                          <Plus className="btn__icon" /> Upload Photo
                        </>
                      )}
                    </button>
                  </div>
                </section>
              )}
            </Dropzone>
            {imageError !== "" && (
              <>
                <div className="invalid-feedback">{imageError}</div>
              </>
            )}
            {(get(initialState, "cover_image", "") ||
              !isEmpty(coverPreviewArr)) && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(coverPreviewArr)
                          //   ? coverPreviewArr.preview
                          get(initialState, "cover_image", "")
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setCoverPreviewArr({});
                            initialState["cover_image"] = "";
                            setInitialState(initialState);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="form-group">
            <label className="label-primary">
              Show Photo (recommended dimensions)
            </label>
            <Dropzone
              accept="image/*"
              multiple={false}
              // disabled={this.state.previewArr.length >= 50}
              onDrop={(acceptedFiles) => addImages(acceptedFiles, "showImage")}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone">
                  <div className="dropzone__area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button type="button" className="btn btn__green">
                      {showLoader ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <>
                          <Plus className="btn__icon" /> Upload Photo
                        </>
                      )}
                    </button>
                  </div>
                </section>
              )}
            </Dropzone>
            {imageError !== "" && (
              <>
                <div className="invalid-feedback">{imageError}</div>
              </>
            )}
            {(get(initialState, "showImage", "") ||
              !isEmpty(showPreviewArr)) && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(showPreviewArr)
                          //   ? showPreviewArr.preview
                          get(initialState, "showImage", "")
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setShowPreviewArr({});
                            initialState["showImage"] = "";
                            setInitialState(initialState);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <button
            onClick={() => updateShowDetails()}
            type="submit"
            className="btn btn-lg btn__green w-100"
          >
            Create Show
          </button>
        </div>
      </div>
    </>
  );
}