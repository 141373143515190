import * as React from "react"

const Edit = ({ ...props }) => {
  return (
    <svg
      width={17}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.692 2.746c-1.098 0-1.988.89-1.988 1.988v7.574c0 1.098.89 1.988 1.988 1.988h7.574c1.098 0 1.988-.89 1.988-1.988V7.74a.852.852 0 0 1 1.705 0v4.567A3.692 3.692 0 0 1 11.266 16H3.692A3.692 3.692 0 0 1 0 12.308V4.734a3.692 3.692 0 0 1 3.692-3.692H7.87a.852.852 0 0 1 0 1.704H3.692Z"
        fill="#5ABDCE"
      />
      <path
        d="m11.672 1.61 2.72 2.72-7.224 7.224-2.716.589a.5.5 0 0 1-.594-.595l.59-2.707 7.224-7.23Zm3.955-.332-.902-.903a1.28 1.28 0 0 0-1.811 0l-.787.787 2.713 2.714.787-.787a1.28 1.28 0 0 0 0-1.811Z"
        fill="#F57B8C"
      />
    </svg>
  );
};



export default Edit
