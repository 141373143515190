import React from "react";
import { Menu, Dropdown } from "antd";
import { More } from "../../icons";

import "./styles.scss";



const options = (data, openModalHide, setModalIsOpen, setOpenModalData, catName) => {


  return (
    <Menu>
      <Menu.Item
        onClick={() => {
          setModalIsOpen(true);
          setOpenModalData(data);
          catName(data?.name);
        }}
        key="1"
      >
        Edit
      </Menu.Item>
      <Menu.Item onClick={() => openModalHide(data)} key="2">
        {data?.appDisplayStatus === "active" ? "Hide" : "UnHide"}
      </Menu.Item>
    </Menu>
  );
};

const optionsEpisodeSeason = (data, openModalHide, setModalIsOpen, setOpenModalData, catName) => {


  return (
    <Menu>
      <Menu.Item
        onClick={() => {
          setModalIsOpen(true);
          setOpenModalData(data);
          catName(data?.name);

        }}
        key="1"
      >
        Edit
      </Menu.Item>
      {/* <Menu.Item onClick={() => openModalHide(data)} key="2">
        {data?.appDisplayStatus === "active" ? "Hide" : "UnHide"} 
      </Menu.Item> */}
    </Menu>
  );
};

const optionsTwo = (
  data,
  openModalHide,
  setModalIsOpen,
  setOpenModalData,
  catName,
  selectedData,
  setImage,
  image,
  setSelectedStatus,
  setSeasonsData,
  setEpisodeData,
  showsListData,
  setSelectedData
) => {
  return (
    <Menu>
      <Menu.Item
        // onClick={() => {
        //   // setSeasonsData([]);
        //   // data["seasonId"] = null;
        //   // data["episodeId"] = null;
        //   setOpenModalData({ ...data });
        //   setModalIsOpen(true);

        //   // setSelectedData({ ...selectedData });
        //   setSelectedStatus(
        //     data.type === "featuredByShow"
        //       ? "Feature by Show"
        //       : "Feature an Episode"
        //   );
        //   // if (data["seasonId"] !== null && data["episodeId"] !== null) {
        //   let seasonData = showsListData.find(
        //     (s) => s._id.toString() === data?.seriesId?._id?.toString()
        //   );
        //   console.log(seasonData, "seasonDatasss");
        //   setEpisodeData([]);
        //   //setSeasonsData([]);

        //   if (seasonData?.isSeason === "false") {
        //     setSeasonsData([]);
        //     setEpisodeData([...seasonData?.episodes]);
        //   } else {
        //     setSeasonsData([...seasonData?.seasons]);

        //     let episodeData = seasonData?.seasons?.find(
        //       (s) => s._id.toString() === data?.seasonId?.toString()
        //     );
        //     // setSeasonsData([]);
        //     setEpisodeData([...episodeData?.episodesIds]);
        //   }
        //   // }
        // }}
        onClick={() => {
          // setImage(image);
          // setSeasonsData([]);
          // data["seasonId"] = null;
          // data["episodeId"] = null;
          // setOpenModalData({ ...data });
          // catName(data?.name);
          setModalIsOpen(true);

          // setSelectedData({ ...selectedData });
          setSelectedStatus(
            data.type === "featuredByShow"
              ? "Feature by Show"
              : data.type === "featuredByEpisodes"
                ? "Feature an Episode"
                : "Feature by URL"
          );

          // if (data["seasonId"] !== null && data["episodeId"] !== null) {
          let seasonData = showsListData.find(
            (s) => s._id.toString() === data?.seriesId?._id?.toString()
          );
          console.log(seasonData, "seasonDatasssActionMoreComp1------>");
          setEpisodeData([]);
          //setSeasonsData([]);

          if (seasonData?.isSeason === "false") {
            let episodes = seasonData?.episodes
            setSeasonsData([]);
            setEpisodeData(episodes);
          } else {
            let seasons = seasonData?.seasons
            setSeasonsData(seasons);

            let episodeData = seasonData?.seasons?.find(
              (s) => s._id.toString() === data?.seasonId?.toString()
            );
            // setSeasonsData([]);
            let episodesIds = episodeData?.episodesIds
            setEpisodeData(episodesIds)
          }
          // }
        }}
        key="1"
      >
        Edit
      </Menu.Item>
      <Menu.Item onClick={() => openModalHide(data)} key="2">
        {/*data?.status === 'active' ? "Hide" : "UnHide"*/}
        Delete
      </Menu.Item>
    </Menu>
  );
};

const optionsThree = (
  data,
  openModalHide,
  setModalIsOpen,
  setOpenModalData,
  catName,
  initialState,
  setInitialState,
) => {
  return (
    <Menu>
      <Menu.Item
        onClick={() => {
          catName(data?.name);
          let newArr = [];
          let result = data?.seriesCount?.map(
            (s, i) => {
              newArr.push(s?._id);
            }
          );
          Promise.all(result);
          initialState['showList'] = newArr;
          setInitialState({ ...initialState });
          setOpenModalData({});
          setOpenModalData({ ...data });
          setModalIsOpen(true);
        }}
        key="3"
      >
        Edit
      </Menu.Item>
      <Menu.Item onClick={() => openModalHide(data)} key="4">
        {/*data?.status === 'active' ? "Hide" : "UnHide"*/}
        Delete
      </Menu.Item>
    </Menu>
  );
};

export default function ActionMoreComponent({
  openModalHide,
  setModalIsOpen,
  setOpenModalData,
  catName,
  data,
  keyV,
  keyS,
  keyU,
  selectedData,
  image,
  setImage,
  setSelectedStatus,
  setSeasonsData,
  setEpisodeData,
  showsListData,
  setSelectedData,
  initialState,
  setInitialState,
  imageTrue,
  setImageTrue
}) {

  if (keyV === "featured-app") {
    console.log(image, 'image')
    return (
      <>
        <Dropdown
          overlay={optionsTwo(
            data,
            openModalHide,
            setModalIsOpen,
            setOpenModalData,
            catName,
            selectedData,
            image,
            setImage,
            setSelectedStatus,
            setSeasonsData,
            setEpisodeData,
            showsListData,
            setSelectedData,
            imageTrue,
            setImageTrue
          )}
          placement="bottomRight"
        >
          <a
            className="btn ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <More />
          </a>
        </Dropdown>
      </>
    );
  } else if (keyS === "test") {
    return (
      <>
        <Dropdown
          overlay={optionsEpisodeSeason(
            data,
            openModalHide,
            setModalIsOpen,
            setOpenModalData,

            catName
          )}
          placement="bottomRight"
        >
          <a
            className="btn ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <More />
          </a>
        </Dropdown>
      </>
    );
  } else if (keyU === "Category") {
    return (
      <>
        <Dropdown
          overlay={optionsThree(
            data,
            openModalHide,
            setModalIsOpen,
            setOpenModalData,
            catName,
            initialState,
            setInitialState
          )}
          placement="bottomRight"
        >
          <a
            className="btn ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <More />
          </a>
        </Dropdown>
      </>
    );
  }
}
